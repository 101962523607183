import React, { useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import LocRatingMap from '../map/loc-rating-map';
import { MapMarkerInfoBox } from '../map/map-marker';


/**
 * Components
 */

const OfficeDetailsMap = (props) => {
    const [] = useState('')


    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = props.lat      
    mapItem['lng']  = props.log 
    const mapMarkerImageShowFlag = 0;
    let mapItemHtml = MapMarkerInfoBox(props.Title, mapMarkerImageShowFlag);
    mapItem['html'] = mapItemHtml;
    mapItems.push( mapItem );
    return (
        <div className="property-map" id="propertymap">
            <LocRatingMap data={mapItems} />
        </div>
    )
}

export default OfficeDetailsMap
